<template>
    <div>
        <HighBoard
            @logOut="logOutClick"
            @clickCompany="openPageCompany"
            @clickDashboard="openPageDashboard"
            @clickModem="openPageModem"
            :username="userOptions.username"
            :role="userOptions.role"
        />
        
        <Transition name="fade" mode="out-in">
            <component :is="activeComponent"></component>
        </Transition>
    </div>
</template>
<script>

import { shallowRef } from 'vue'

import { mapMutations, mapGetters, mapActions } from 'vuex'
// import CompressorWidget from '@/components/Simple_elements/CompressorWidget.vue'
// import WindowGraph from '@/components/Simple_elements/WindowGraph.vue'
// import WindowParam from '@/components/Simple_elements/WindowParam.vue'
// import WindowError from '@/components/Simple_elements/WindowError.vue'
import HighBoard from '@/components/Simple_elements/HighBoard.vue'
// import MyCollapse from '@/components/Simple_elements/MyCollapse.vue'
import DashboardVue from './Simple_elements/DashboardPage.vue'
import CompanyVue from './Simple_elements/CompanyPage.vue'
import ModemPageVue from './Simple_elements/ModemPage.vue'

export default {
    name: 'MainWindow',
    computed: {
        ...mapGetters({
            userOptions: 'getUserOptions',
            // compressorsList: 'getCompressorsList',
            // StatusDescriptions: 'getStatusDescriptions'
        })
    },
    data() {
        return {
            activeComponent: shallowRef(DashboardVue),
            // switcherGraph: false,
            // switcherParam: false,
            // switcherError: false,
            // paramWindow: {
            // },
            // graphWindow: {
            // },
            // errorWindow: {
            // },
            // flagStopTimeout: false
        }
    },
    components: {
        // CompressorWidget,
        // WindowGraph,
        // WindowParam,
        // WindowError,
        HighBoard,
        DashboardVue,
        CompanyVue,
        ModemPageVue
        // MyCollapse
    },
    methods: {
        // ...mapMutations({
        //     setLogin: 'setLogin'
        // }),
        ...mapActions({
            postLogout: 'postLogout',
            getCompressors: 'getCompressors',
            getStatusDescriptions: 'getStatusDescriptions',
        }),
        logOutClick() {
            // this.setLogin(!this.userOptions.loginStatus)
            this.postLogout()
        },
        openPageCompany() {
            this.activeComponent = shallowRef(CompanyVue)
            
        },
        openPageDashboard() {
            this.activeComponent = shallowRef(DashboardVue)
            
        },
        openPageModem() {
            this.activeComponent = shallowRef(ModemPageVue)
        },
        openGraphWindow(id, name, mac, type_controller) {
            this.switcherGraph = true
            this.graphWindow = {
                id: id,
                name: name,
                mac: mac,
                type_controller: type_controller
            }
        },
        closeGraphWindow() {
            this.switcherGraph = false
            this.graphWindow = {}
        },
        openParamWindow(id, name) {
            this.switcherParam = true
            for (const property in this.compressorsList) {
                this.compressorsList[property].compressors.find(el=>{
                    if (el.id==id && el.name == name) {
                        this.paramWindow.id = el.id
                        this.paramWindow.mac = el.mac
                        this.paramWindow.name = el.name
                        this.paramWindow.date = el.created_date
                        this.paramWindow.company = el.company_id
                        this.paramWindow.type_controller = el.type_controller
                    }
                })
            }
        },
        closeParamWindow() {
            this.switcherParam = false
            this.paramWindow = {}
        },
        openErrorWindow(id, name, type_controller) {
            this.switcherError = true
            this.errorWindow = {
                id: id,
                name: name,
                baseError: this.StatusDescriptions[type_controller].errors

            }
        },
        closeErrorWindow() {
            this.switcherError = false
            this.errorWindow = {}
        },
        getDiscriptionsOfStatus(findArray, code) {
            // console.log(findArray, code)
            if (findArray) {
                let description = 'Неопределенный статус'; 
                findArray.find(el => {
                    if (el.code == code) {
                        description = el.description
                    }
                })
                return description
            } else {
                return ''
            }
        },
        // updateData() {
        //     this.getCompressors();
        //     if (!this.flagStopTimeout) {
        //         setTimeout(this.updateData, 10000)
        //     }
        // }
    },
    created() {
        // this.flagStopTimeout = false
        // this.getCompressors();
        // setTimeout(this.updateData, 10000)
        // this.getStatusDescriptions()
    },
    beforeUnmount() {
        // this.flagStopTimeout = true
    }
}

</script>
<style noscoped lang="scss">
// .high-board{
//     width: 100vw;
//     background: #3F51B5;
//     height: 7vh;
//     box-shadow: 3px 13px 8px 0px rgba(34, 60, 80, 0.2);
//     display: flex;
//     align-items: center;
    
//     .logout{
//         cursor: pointer;
//         margin: 0px 1vw;
//         height: 50%;
//         background: #fff;
//         padding: 4px 10px;
//         border-radius: 4px;
//         i{
//             margin: 0px 6px;
//             color:#3f51b5;
//         }
//     }
// }
.solo-company {
    overflow: hidden;
    transition: all .2s ease;
    padding: 24px 30px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}
.main-dask{
    height: 93vh;//issue: should be 93vh
    // display: flex;
    // align-items: flex-start;
    // justify-content: flex-start;
    // flex-direction: row;
    // flex-wrap: wrap;
    // align-content: flex-start;
    padding: 10px 1vw;
    overflow-y: auto;
}

</style>



