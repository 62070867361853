<template>
    <div class="create-users-form">
        <h1 class="middle-high">{{nameElementForm}}</h1>
        <div class="change-passford-form" v-if="flagChangeForm">
            <h3 class="name-form plain-text">Изменить пароль ({{ paramUserChangePassword.name }}):</h3>
            <input type="text" v-model="changingPassword" class="plain-text" placeholder="Пароль">
            <div style="display:flex; justify-content: space-between;">
                <button class="plain-text abnormal-button" @click="closeFormChangePass">Отменить</button>
                <button class="plain-text abnormal-button" @click="changePassword">Изменить</button>
            </div>
        </div>
        <h3 class="name-form plain-text">Добавить нового пользователя:</h3>
        <input type="text" v-model="loginValue" class="plain-text" placeholder="Логин">
        <input type="text" v-model="passwordValue" class="plain-text" placeholder="Пароль">
        <button class="plain-text" @click="clickAddNewUser">Добавить</button>
        <h3 class="name-form plain-text">{{ nameChangeForm }}</h3>
        <input type="text" v-model="newNameElement" class="plain-text" :placeholder="nameLinePlaceholder">
        <input type="text" v-model="newShortNameElement" class="plain-text" :placeholder="'Короткое имя на Английскоми ('+ shortNameElement + ')'">
        <button class="plain-text" @click="clickChangeName">Изменить</button>
        <button class="big-button plain-text" @click="clickDelete">Удалить</button>
    </div>
</template>

<script>

export default {
    name: 'DealerCompanyForm',
    props: ['nameElementForm', 'nameElement', 'shortNameElement', 'typeForm', 'idDealer', 'idCompany', 'flagChangeForm', 'idUserChangedPassword', 'paramUserChangePassword'],
    emits: ['changeDealer', 'changeCompany', 'addNewUser', 'closeFormChangePassword', 'clickChangePassword', 'clickDeleteDealer', 'clickDeleteCompany'],
    computed: {
        nameLinePlaceholder() {
            if (this.typeForm=='dealer') {
                return 'Название дилера' + ' ('+ this.nameElement + ')'
            } else {
                return 'Название коипании'+' ('+ this.nameElement + ')'
            }
        },
        nameChangeForm() {
            if (this.typeForm=='dealer') {
                return 'Изменить параметры дилера:'
            } else {
                return 'Изменить параметры компании:'
            }
        }
    },
    data() {
        return {
            newShortNameElement: '',
            newNameElement: '',
            loginValue: '',
            passwordValue: '',
            changingPassword: ''
        }
    },
    components: {
    },
    methods: {
        clickAddNewUser() {
            const options = {
                username: this.loginValue,
                password: this.passwordValue
            }
            if (this.typeForm=='dealer') {
                options.dealer_id = this.idDealer
                options.company_id = null
                options.role = 'dealer'
            } else {
                options.dealer_id = this.idDealer
                options.company_id = this.idCompany
                options.role = 'user'
            }
            this.$emit('addNewUser', options)
            this.loginValue = ''
            this.passwordValue = ''
        },
        clickChangeName() {
            let options = {
                param: {}
            }
            if (this.newNameElement == '' && this.newShortNameElement == '') {
                return;
            }
            if (this.newNameElement != '') {
                options.param.showing_name = this.newNameElement
            } else {
                options.param.showing_name = this.nameElement
            }
            if (this.shortNameCompany != '') {
                options.param.name = this.newShortNameElement
            } else {
                options.param.name = this.shortNameElement
            }
            this.newShortNameElement = ''
            this.newNameElement = ''
            if (this.typeForm == 'dealer') {
                options.id = this.idDealer
                this.$emit('changeDealer', options)
            } else {
                options.id = this.idCompany
                options.param.dealer_id = this.idDealer
                this.$emit('changeCompany', options)
            }
            
        },
        closeFormChangePass() {
            this.$emit('closeFormChangePassword')
        },
        changePassword() {
            let options = {}
            options.id = this.paramUserChangePassword.id
            options.password = this.changingPassword
            options.username = this.paramUserChangePassword.name
            this.$emit('clickChangePassword', options)
            this.changingPassword = ''
        },
        clickDelete() {
            if (this.typeForm=='dealer') {
                if (confirm('Удалить дилера "' + this.nameElement +'"?')) {
                    this.$emit('clickDeleteDealer', this.idDealer)
                }
            } else if (this.typeForm=='company') {
                if (confirm('Удалить компанию "' + this.nameElement +'"?')) {
                    this.$emit('clickDeleteCompany', this.idCompany)
                }
            }
            
        }
    },
    created() {
        // this.shortNameCompany = this.shortNameCompany
        // this.nameCompany = this.nameUserForm
    },
    beforeUnmount() {
    }
}
</script>

<style noscoped lang="scss">

.create-users-form {
    display: flex;
    flex-direction: column;
    padding: 0 25px;

    .change-passford-form {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        transition: all .3s ease;
        .abnormal-button {
            margin: 20px 0;
        }
    }
    .name-form {
        margin-top: 25px;
    }

    input {
        border: 1px solid var(--gray-dark-color);
        border-radius: 4px;
        padding: 3px 6px;
        margin-top: 20px;
    }
    button {
        border-radius: 8px;
        background: var(--background-blue);
        color: #fff;
        padding: 6px 50px;
        margin-top: 20px;
        margin-left: auto;
        transition: all .1s ease;

        &:hover {
            background: #3f51b5d0;
        }
        &:active {
            background: #3f51b55e;
        }
    }
    .big-button {
        width: 100%;
        background: rgba(245, 99, 99, 0.808);
        transition: all .1s ease;
        &:hover {
            background: rgba(245, 99, 99, 0.877);
        }
        &:active {
            background: rgba(245, 99, 99, 0.582);
        }
    }
}

</style>