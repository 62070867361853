<template>
    <input :type="type" :placeholder="placeholder" :value="modelValue" @input="updateValue">
</template>

<script>
export default {
    name: 'InputLine',
    props: {
        type: String,
        placeholder: String,
        modelValue: ''
    },
    methods: {
        updateValue(event) {
            this.$emit('update:modelValue', event.target.value);
        }
    }
}
</script>
<style scoped lang="scss">

input {
        border: 1px solid var(--gray-dark-color);
        border-radius: 4px;
        padding: 3px 6px;
        // margin-top: 20px;
        transition: all .4s;
}
input::placeholder{
    color: #87869D;
}
input:hover{
    box-shadow: 0px 4px 15px 0px rgba(191, 191, 191, 0.25);
}

</style>
