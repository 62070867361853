<template>
    <div class="board-place">
        <div class="work-line">
            <div class="work-part">
                <img src="@/assets/imgs/LogoMvlab.svg" class="wide-type" alt="">
                <img
                    src="@/assets/imgs/menu-line.svg"
                    style="cursor: pointer"
                    v-if="!windowMenu"
                    class="narrow-type"
                    alt=""
                    @click="clickMenuLine"
                >
                <img 
                    src="@/assets/imgs/crosser.svg"
                    style="cursor: pointer"
                    v-if="windowMenu"
                    class="narrow-type"
                    alt=""
                    @click="closeAllWindow"
                >
                <button
                    class="middle-high"
                    :class="{
                        choosen: choosenPage == 'dashboard'
                    }"
                    @click="clickDashboard"
                >Dashboard</button>
                <button
                    class="middle-high"
                    :class="{
                        choosen: choosenPage == 'company'
                    }"
                    v-if="role == 'developer' || role == 'dealer' || role == 'admin'"
                    @click="clickCompany"
                >Company</button>
                <button
                    class="middle-high"
                    :class="{
                        choosen: choosenPage == 'modem'
                    }"
                    v-if="role == 'developer' || role == 'admin'"
                    @click="clickModem"
                >Modem</button>
            </div>
            <div class="user-part" @click="clickUserLine">
                <img src="@/assets/imgs/profile.svg" alt="">
                <p class="plain-text">{{username}}</p>
                <img src="@/assets/imgs/arrow-down.svg" alt="" class="arrow-img" :class="{choosen: windowUser}">
            </div>
        </div>
        <div class="shadow-place" v-if="windowShadow" @click.self="closeAllWindow">
            <div class="user-window" v-if="windowUser">
                <p class="plain-text">Приветствую, {{username}}</p>
                <p class="plain-text">Роль: {{role}}</p>
                <button @click="logOut">
                    <img src="@/assets/imgs/exit.svg" alt="">
                    Выйти
                </button>
            </div>
            <div class="menu-window" v-if="windowMenu">
                <button class="plain-text choosen">Dashboard</button>
                <button class="plain-text">Company</button>
            </div>
        </div>
    </div>
    <div class="main-dask">
        <div v-if="Object.keys(compressorsList).length != 1">
            <MyCollapse
                v-for="keyCompany in Object.keys(compressorsList).sort((a, b) => {
                    if (a === 'unreg' && b !== 'unreg') {
                        return 1;
                    } else if (a !== 'unreg' && b === 'unreg') {
                        return -1;
                    } else {
                        return 0;
                    }
                })"
                :nameCollapse="createNameCollapse(keyCompany)"
                :key="keyCompany"
            >   
                <CompressorWidget
                    v-for="item in compressorsList[keyCompany].compressors" :key="item.id"
                    :name="item.name"
                    :mac="item.mac"
                    :type_controller="item.type_controller"
                    :pressure_in="item.pressure_in"
                    :pressure_out="item.pressure_out"
                    :temperature="item.temperature"
                    :vibration="item.vibration"
                    :error="item.error"
                    :errorDescription="getDiscriptionsOfStatus(StatusDescriptions[item.type_controller]?.errors, item.error)"
                    :status="item.status"
                    :statusDescription="getDiscriptionsOfStatus(StatusDescriptions[item.type_controller]?.status, item.status)"
                    :work_percent="item.work_percent"
                    :total_time="item.total_time"
                    :off_load_time="item.off_load_time"
                    :onload_time="item.onload_time"
                    :time_air_filter="item.time_air_filter"
                    :time_oil="item.time_oil"
                    :engine_current="item.current_engine"
                    :separator_filter="item.time_separator_filter"
                    :time_oil_liquid="item.time_change_oil_liquid"
                    :time_el_system="item.time_el_system"
                    :voltage="item.voltage"
                    :alarm="item.alarm"
                    :alarmDescription="getDiscriptionsOfStatus(StatusDescriptions[item.type_controller]?.alarm, item.alarm)"
                    :lastDate="item.last_time_writen"
                    :id="item.id"
                    @open-graf="openGraphWindow"
                    @open-param="openParamWindow"
                    @open-error="openErrorWindow"
                />
            </MyCollapse>
        </div>
        <div class="solo-company" v-else v-for="company, companyName in compressorsList" :key="companyName">
            <CompressorWidget
                v-for="item in company.compressors" :key="item.id"
                :name="item.name"
                :mac="item.mac"
                :type_controller="item.type_controller"
                :pressure_in="item.pressure_in"
                :pressure_out="item.pressure_out"
                :temperature="item.temperature"
                :vibration="item.vibration"
                :error="item.error"
                :errorDescription="getDiscriptionsOfStatus(StatusDescriptions[item.type_controller]?.errors, item.error)"
                :status="item.status"
                :statusDescription="getDiscriptionsOfStatus(StatusDescriptions[item.type_controller]?.status, item.status)"
                :work_percent="item.work_percent"
                :total_time="item.total_time"
                :off_load_time="item.off_load_time"
                :onload_time="item.onload_time"
                :time_air_filter="item.time_air_filter"
                :time_oil="item.time_oil"
                :engine_current="item.current_engine"
                :separator_filter="item.time_separator_filter"
                :time_oil_liquid="item.time_change_oil_liquid"
                :time_el_system="item.time_el_system"
                :voltage="item.voltage"
                :alarm="item.alarm"
                :alarmDescription="getDiscriptionsOfStatus(StatusDescriptions[item.type_controller]?.alarm, item.alarm)"
                :lastDate="item.last_time_writen"
                :id="item.id"
                @open-graf="openGraphWindow"
                @open-param="openParamWindow"
                @open-error="openErrorWindow"
            />
        </div>
        <Transition name="fade" mode="out-in">
            <WindowGraph
                v-if="switcherGraph"
                @closeWindow="closeGraphWindow"
                :name="graphWindow.name"
                :id="graphWindow.id"
                :mac="graphWindow.mac"
                :type_controller="graphWindow.type_controller"
            />
        </Transition>
        <Transition name="fade" mode="out-in">
            <WindowParam
                v-if="switcherParam"
                @closeWindow="closeParamWindow"
                :id="paramWindow.id"
                :mac="paramWindow.mac"
                :nameCompressor="paramWindow.name"
                :date="paramWindow.date"
                :type_controller="paramWindow.type_controller"
                :company_id="paramWindow.company"
                :dealer_id="paramWindow.dealer"
            />
        </Transition>
        <Transition name="fade" mode="out-in">
            <WindowError
                v-if="switcherError"
                @closeWindow="closeErrorWindow"
                :id="errorWindow.id"
                :name="errorWindow.name"
                :errorBase="errorWindow.baseError"
            />
        </Transition>
    </div>
</template>
<script>

import { mapGetters, mapActions } from 'vuex'
import CompressorWidget from '@/components/Simple_elements/CompressorWidget.vue'
import WindowGraph from '@/components/Simple_elements/WindowGraph.vue'
import WindowParam from '@/components/Simple_elements/WindowParam.vue'
import WindowError from '@/components/Simple_elements/WindowError.vue'
import HighBoard from '@/components/Simple_elements/HighBoard.vue'
import MyCollapse from '@/components/Simple_elements/MyCollapse.vue'

export default {
    name: 'TestDashboard',
    computed: {
        ...mapGetters({
            userOptions: 'getUserOptions',
            // StatusDescriptions: 'getStatusDescriptions'
        }),
    },
    data() {
        return {
            switcherGraph: false,
            switcherParam: false,
            switcherError: false,
            timeOutID: 0,
            paramWindow: {
            },
            graphWindow: {
            },
            errorWindow: {
            },
            flagStopTimeout: false,
            compressorsList: [],
            StatusDescriptions: []
        }
    },
    components: {
        CompressorWidget,
        WindowGraph,
        WindowParam,
        WindowError,
        HighBoard,
        MyCollapse
    },
    methods: {
        ...mapActions({
            postLogout: 'postLogout',
        }),
        createNameCollapse(index) {
            if (this.compressorsList[index].showing_name_of_dealer && (this.userOptions.role == 'developer' || this.userOptions.role == 'admin')) {
                return this.compressorsList[index].showing_name_of_company + ' (' + this.compressorsList[index].showing_name_of_dealer + ')'
            } else {
                return this.compressorsList[index].showing_name_of_company
            }
            
        },
        logOutClick() {
            this.postLogout()
        },
        openGraphWindow(id, name, mac, type_controller) {
            this.switcherGraph = true
            this.graphWindow = {
                id: id,
                name: name,
                mac: mac,
                type_controller: type_controller
            }
        },
        closeGraphWindow() {
            this.switcherGraph = false
            this.graphWindow = {}
        },
        openParamWindow(id, name) {
            this.switcherParam = true
            for (const property in this.compressorsList) {
                this.compressorsList[property].compressors.find(el=>{
                    if (el.id==id && el.name == name) {
                        this.paramWindow.id = el.id
                        this.paramWindow.mac = el.mac
                        this.paramWindow.name = el.name
                        this.paramWindow.date = el.created_date
                        this.paramWindow.company = el.company_id
                        this.paramWindow.dealer = el.dealer_id
                        this.paramWindow.type_controller = el.type_controller
                    }
                })
            }
        },
        closeParamWindow() {
            this.switcherParam = false
            this.paramWindow = {}
        },
        openErrorWindow(id, name, type_controller) {
            this.switcherError = true
            this.errorWindow = {
                id: id,
                name: name,
                baseError: this.StatusDescriptions[type_controller].errors

            }
        },
        closeErrorWindow() {
            this.switcherError = false
            this.errorWindow = {}
        },
        getDiscriptionsOfStatus(findArray, code) {
            // console.log(findArray, code)
            if (findArray) {
                let description = 'Неопределенный статус'; 
                findArray.find(el => {
                    if (el.code == code) {
                        description = el.description
                    }
                })
                return description
            } else {
                return ''
            }
        },
        updateData() {
            // this.getCompressors();
            if (!this.flagStopTimeout) {
                this.timeOutID = setTimeout(this.updateData, 10000)
            }
        }
    },
    created() {
        this.flagStopTimeout = false
        this.timeOutID = setTimeout(this.updateData, 10000)
    },
    beforeUnmount() {
        clearTimeout(this.timeOutID);
        this.flagStopTimeout = true
    }
}

</script>
<style noscoped lang="scss">
// .high-board{
//     width: 100vw;
//     background: #3F51B5;
//     height: 7vh;
//     box-shadow: 3px 13px 8px 0px rgba(34, 60, 80, 0.2);
//     display: flex;
//     align-items: center;
    
//     .logout{
//         cursor: pointer;
//         margin: 0px 1vw;
//         height: 50%;
//         background: #fff;
//         padding: 4px 10px;
//         border-radius: 4px;
//         i{
//             margin: 0px 6px;
//             color:#3f51b5;
//         }
//     }
// }
// .solo-company {
//     overflow: hidden;
//     transition: all .2s ease;
//     padding: 24px 30px;
//     display: grid;
//     grid-template-columns: repeat(3, 1fr);
//     gap: 10px;
// }
// .main-dask{
//     height: 93vh;//issue: should be 93vh
//     // display: flex;
//     // align-items: flex-start;
//     // justify-content: flex-start;
//     // flex-direction: row;
//     // flex-wrap: wrap;
//     // align-content: flex-start;
//     background-image: url("@/assets/imgs/logo-small.png");
//     background-repeat: no-repeat;
//     background-size: 15%;
//     background-position: 99% 99%;
//     padding: 10px 1vw;
//     overflow-y: auto;
// }

</style>



