import { createApp } from 'vue'
import App from './App.vue'
import store from './store'

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import Vue from 'vue'
import HighchartsVue from 'highcharts-vue'

import axios from '../config-axios'
import VueAxios from 'vue-axios'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

createApp(App).use(store).use(HighchartsVue).use(VueAxios, axios).component('VueDatePicker', VueDatePicker).use(ElementPlus).mount('#app')
