<template>
        <input :type="type" :placeholder="placeholder" :value="modelValue" @input="updateValue">
</template>

<script>
    export default {
        name: 'Input',
        props: {
            type: String,
            placeholder: String,
            modelValue: ''
        },
        methods: {
            updateValue(event) {
                this.$emit('update:modelValue', event.target.value);
            }
        }
    }
</script>
<style scoped lang="scss">

    input{
        border-radius: 0.5625rem;
        border: 1px solid #E1E2EB;
        transition: all .3s ease;
    }
    input::placeholder{
        color: #87869D;
    }
    input:hover{
        box-shadow: 0px 4px 15px 0px rgba(191, 191, 191, 0.25);
    }

</style>
