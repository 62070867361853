<template>
    <div class="user-line">
        <p class="login-part plain-text">{{name}}</p>
        <div class="pasword-part">
            <button @click="changePassword">Изм. пароль</button>
            <button @click="deleteUser">Удалить</button>
        </div>
    </div>
</template>
<script>
export default {
    name: 'UserLine',
    props: ['name', 'id'],
    emits: ['changePassword', 'deleteUser'],
    computed: {
    },
    data() {
        return {
        }
    },
    components: {
    },
    methods: {
        changePassword() {
            this.$emit('changePassword', {
                id: this.id,
                name: this.name
            })
        },
        deleteUser() {
            this.$emit('deleteUser', {
                id: this.id,
                name: this.name
            })
        }
    },
    created() {
    },
    beforeUnmount() {
    }
}
</script>

<style noscoped lang="scss">
.user-line {
    display: flex;
    border: 1px solid var(--gray-dark-color);
    border-radius: 4px;
    padding: 2px 11px;
    // justify-content: space-between;
    .login-part {
        margin-right: auto;
    }
    .pasword-part {
        display: flex;
        
        button {
            padding: 0 5px;
            margin-left: 5px;
            background: none;
            border: 1px solid var(--gray-dark-color);
        }
    }
    &:not(:first-child) {
        margin-top: 3px;
    }
}
</style>