<template>
    <div class="create-users-list">
        <h3 class="plain-text">Пользователи:</h3>
        <div class="name-columns">
            <p>Логин</p>
        </div>
        <div class="user-list-place">
            <div class="empty-list-user" v-if="userList.length == 0">Нету пользователей</div>
            <UserLine v-for="item in userList" :key="item.id"
                :name="item.username"
                :id="item.id"
                @changePassword="changeUserPass"
                @deleteUser="deleteUser"
            />
        </div>
    </div>
</template>

<script>
import UserLine from './UserLine.vue'


export default {
    name: 'UsersListForm',
    props: ['userList'],
    emits: ['deleteUser', 'changeUser'],
    computed: {
    },
    data() {
        return {
        }
    },
    components: {
        UserLine
    },
    methods: {
        deleteUser(param) {
            this.$emit('deleteUser', param)
        },
        changeUserPass(param) {
            this.$emit('changeUser', param)
        }
    },
    created() {
    },
    beforeUnmount() {
    }
}
</script>

<style noscoped lang="scss">
.create-users-list {
    display: flex;
    flex-direction: column;
    padding: 0 25px;

    .name-columns {
        margin-top: 15px;
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .user-list-place {
        margin-top: 3px;
        display: flex;
        flex-direction: column;
        width: 100%;

        .empty-list-user {
            border: 1px solid var(--gray-dark-color);
            border-radius: 4px;
            padding: 2px 11px;
            display: flex;
            justify-content: center;
        }
    }

}
</style>