<template>
    <div class="main-place">
        <WindowAbout class="window-hiden"
            :class="{
                'hide-mode': !statusWindowAbout,
                'show-mode': statusWindowAbout
            }" 
            @close-window="openWindowAbout"
        />
        <div class="left-place">
            <div class="top-line">
                <img src="@/assets/imgs/LogoMvlab.svg" alt="">
            </div>
            
            <el-carousel trigger="click" height="450px" :interval="8000">
            <el-carousel-item v-for="item, index in nameImages" :key="index">
                <div class="middle-line">
                    <img :src=item alt="">
                </div>
            </el-carousel-item>
                
            </el-carousel>
            <div class="bottom-line">
                <p>Добро пожаловать. «РЕМЕЗА» – ведущий производитель компрессоров. Наше приложение предназначено для сбора и отображения параметров компрессоров, а также предоставления вам доступа к актуальным данным и показателям в удобном и понятном формате.</p>
            </div>
        </div>
        <div class="right-place">
            <div class="form-place">
                <img class="logo-small-mode" src="@/assets/imgs/LogoMvlab.svg" alt="">
                <h1>Вход</h1>
                <h3>Добро пожаловать! Пожалуйста, введите ваши данные</h3>
                <div class="input-place">
                    <Input class="input-login" type="text" placeholder="Введите логин" @keyup.enter="clickLogin" v-model="username"/>
                    <Input class="input-login" type="password" placeholder="Введите пароль" @keyup.enter="clickLogin" v-model="password"/>
                </div>
                <!-- <button>Войти</button> -->
                <Button @click="clickLogin" class="button-login" :loading="loaderButton" text="Войти"/>
                <button @click="openWindowAbout" class="button-info">Инфо. о продукте</button>
                <!-- <button @click="clickTestPage" class="button-info button-test">Тестовая страница</button> -->
            </div>
        </div>
    </div>    
</template>

<script>
import Input from './Simple_elements/Input.vue'
import Button from './Simple_elements/Button.vue'
import WindowAbout from './LoginWindowAbout.vue'
import { mapMutations, mapGetters, mapActions } from 'vuex'
// Images for corusele component
import firstImage from "@/assets/imgs/slide_.png"
import secondImage from "@/assets/imgs/med.png"
import thirdImage from "@/assets/imgs/sl_main_0.png"
import fourthImage from "@/assets/imgs/slide_4_new.png"
import fivthImage from "@/assets/imgs/slide.png"
////
    export default {
        name: 'LoginWindow',
        components: {
            Input,
            Button,
            WindowAbout
        },
        data() {
            return {
                statusWindowAbout: false,
                nameImages: [firstImage, secondImage, thirdImage, fourthImage, fivthImage],
                username: "",
                password: "",
            }
        },
        computed: {
            ...mapGetters({
                userOptions: 'getUserOptions',
                loaderButton: 'getLoaderLoginButton'
            })
        },
        methods: {
            ...mapMutations({
                setLogin: 'setLogin',
                setUserParam: 'setUserParam'
            }),
            ...mapActions({
                postLogin: 'postLogin'
            }),
            clickLogin() {
                let options = {
                    username: this.username,
                    password: this.password
                }
                this.postLogin(options)
            },
            clickTestPage() {
                let userParam = {
                    username: 'demo_name',
                    role: 'demo'
                }
                this.setUserParam(userParam);
            },
            openWindowAbout() {
                this.statusWindowAbout = !this.statusWindowAbout
            }
        }
    }
</script>
<style noscoped lang="scss">
.window-hiden {
    overflow: hidden;
    transition: opacity .3s ease-out, transform .3s ease-out;
}
.hide-mode {
    opacity: 0;
    transform: translateX(-20px);
    z-index: -1;
}
.show-mode {
    display: inline-block;
    opacity: 1;
    z-index: 1;
}
.button-info{
    border-radius: 0.5625rem;
    border: 2px solid #4F71C0;
    background-color: rgba(255, 255, 255, 0);
    color: #4F71C0;
    transition: all .3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28.5625rem;
    height: 4.75rem;
    padding: 1.38rem;
    font-size: 1.5rem;
    margin-top: 1.2rem;
    display: none;
}
.button-test {
    display: inline-block;
    cursor: pointer;
    // position: absolute;
    // right: 0;
    // bottom: 0;
    transition: all .2s ease;

    &:hover {
        background: #4f71c075;
        color: #FCFCFC;
    }
    &:active {
        background: #4f71c036;
        // color: #FCFCFC;
        color: #4F71C0;
    }
}
.logo-small-mode {
    opacity: 0;
    position: absolute;
}
.main-place{
    display: flex;
}
.left-place{
    background-color: #3F51B5;
    width: 50vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 3.13rem;
}
.left-place .middle-line{
    display: flex;
    justify-content: center;
}
.left-place .bottom-line p{
    color: #FCFCFC;
    width: 37.8125rem;
}
.right-place{
    position: relative;
    display: grid;
    height: 100vh;
    z-index: 0;
    // padding-left: 11.07rem;
    padding-left: 9.3vw;
    align-items: center;
}
.right-place .form-place{
    display: grid;
    grid-template-columns: 1fr;
}
.right-place .form-place h1{
    font-size: 4rem;
}
.right-place .form-place h3{
    margin-top: 1.5rem;
    font-size: 1.5rem;
    font-weight: 400;
    color: #87869D;
}
.right-place .form-place .input-place{
    margin-top: 2.3rem;
    display: grid;
    gap: 1.56rem;
    grid-template-columns: repeat(1, 1fr);
}
.right-place .form-place .input-login{
    width: 28.5625rem;
    padding: 1.25rem;
    font-size: 1.25rem;
}
.right-place .form-place .button-login{
    width: 28.5625rem;
    height: 4.75rem;
    margin-top: 3.41rem;
    padding: 1.38rem;
    font-size: 1.5rem;
}
@media screen and (max-width: 1320px) {
    .left-place{
        display: none;
    }
    .logo-small-mode {
        opacity: 1;
        left: 3.13vw;
        top: 3.13vh;
    }
    .button-info{
        display: inline-block;
    }
}

@media screen and (max-width: 540px) {
    .left-place{
        display: none;
    }
    .logo-small-mode {
        opacity: 1;
    }
    .button-info{
        display: inline-block;
    }
    .right-place .form-place .input-login{
        width: 21rem;
        padding: 1.25rem;
        font-size: 1.25rem;
    }
    .right-place .form-place .button-login{
        width: 21rem;
        height: 4.75rem;
        margin-top: 3.41rem;
        padding: 1.38rem;
        font-size: 1.5rem;
    }
    .button-info{
        width: 21rem;  
    }
}

@media screen and (max-height: 900px) {
    .logo-small-mode {
        height: 24px;
    }
    .left-place .middle-line img{
        height: 450px;
    }
}
</style>
