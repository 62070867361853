<template>
    <div class="right-window">
        <div class="back-button">
            <button @click="closeRightPart()">
                <img src="@/assets/imgs/crosser-black.svg" alt="">
            </button>
        </div>
        <div class="change-name">
            <p class="param-line">Наименование: 
                <el-input  v-model="changeParam.name" class="plain-text" size="small" style="width: 240px" placeholder="name" />
                <!-- <InputLine class="param-line" type="text" placeholder="name" v-model="changeParam.name" /> -->
            <!-- <button
                @click="writeName(modems)"
            >Записать</button> -->
        </p>
        </div>
        
        <p class="param-line plain-text">MAC адрес: <strong>{{modemsData.mac_address}}</strong></p>
        <p class="param-line plain-text">Верисия: <strong>{{modemsData.version}}</strong></p>
        <p class="param-line plain-text">Последнее соединение: <strong>{{ modemsData.last_connection }}</strong></p>
        <p class="param-line plain-text">Название дилера:
            <el-select
                v-model="changeParam.dealerId"
                placeholder="Select"
                size="small"
                @change="changeDealerClick"
            >
                <el-option
                    v-for="item in dealerList"
                    :key="item.label"
                    :label="item.label"
                    :value="item.id"
                />
            </el-select>
        </p>
        <p class="param-line plain-text">Название компании:
            <el-select
                v-model="changeParam.companyId"
                placeholder="Select"
                size="small"
            >
                <el-option
                    v-for="item in companySmartList"
                    :key="item.label"
                    :label="item.label"
                    :value="item.id"
                />
            </el-select>
        </p>
        <p class="param-line plain-text">
            <button class="change-param" @click="writeParam">Записать</button>
        </p>
        <p class="param-line plain-text">Подключённые компрессоры:</p>
        <div class="compressor-list">
            <div class="compressor-line" v-if="!modemData.loadingFlag" v-for="comp in modemData.compressors" :key="comp.id" >
                <p class="plain-text">name: <strong>{{ comp.name }}</strong></p>
                <p class="plain-text">mac-addres: <strong>{{ comp.mac_address }}</strong></p>
            </div>
            <div class="loader-part" v-if="modemData.loadingFlag">
                <Loader />
            </div>
        </div>
    </div>
</template>

<script>
import InputLine from '@/components/Simple_elements/InputLine.vue'
import Loader from '@/components/Simple_elements/Loader.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'ModemPageRightPartForm',
    props: ['modemsData'],
    emits: ['closeMethods'],
    computed: {
        ...mapGetters({
            modemData: 'getModemPageRightPartData',
            userOptions: 'getUserOptions',
            companyList: 'getCompanyList',
            dealerList: 'getDealerList'
        }),
        companySmartList() {
            if (this.userOptions.role == 'admin' || this.userOptions.role == 'developer') {
                return this.companyList.filter(el=> {
                    if (el.dealer_id == this.changeParam.dealerId || el.id == 0) {
                        return true
                    } else {
                        return false
                    }
                })
            } else {
                return this.companyList
            }
        },
    },
    watch: {
    },
    data() {
        return {
            changeParam: {
                name: '',
                companyId: 0,
                dealerId: 0
            }
        }
    },
    components: {
        InputLine,
        Loader
    },
    methods: {
        ...mapActions({
            getCompressors: 'getApiCompressorsByModem',
            putModemParam: 'putApiModemParam',
            getCompanyList: 'getApiCompanyList',
            getDealerList: 'getApiDealerList'
        }),
        writeParam() {
            let payload = {
                id: this.modemsData.id,
                name: this.changeParam.name,
                dealer_id: this.changeParam.dealerId,
                company_id: this.changeParam.companyId
            }
            this.putModemParam(payload);
        },
        closeRightPart() {
            this.$emit('closeMethods')
        },
        changeDealerClick(opt) {
            // alert('work')
            // console.log(opt)
            this.companyId = 0
        },
    },
    created() {
        this.changeParam.name = this.modemsData.name;
        this.changeParam.companyId = this.modemsData.company_id
        this.changeParam.dealerId = this.modemsData.dealer_id
        this.getCompressors(this.modemsData.id);
        if (this.userOptions.role != 'user') {
            this.getCompanyList();
        }
        // console.log(this.userOptions.role)
        if (this.userOptions.role == 'admin' || this.userOptions.role == 'developer') {
            this.getDealerList();
        }
    },
    beforeUnmount() {
    }
}
</script>

<style noscoped lang="scss">
.right-window {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
    padding: 10px 15px;
    max-height: 85vh;
    // overflow: hidden;

    .back-button {
        display: flex;
        justify-content: flex-end;

        button {
            padding: 1px 2px;
            background: none;
            cursor: pointer;
            justify-self: flex-end;
        }
    }
    .change-param {
        cursor: pointer;
        padding: 5px 10px;
        width: 200px;
        // margin-left: 5px;
        background: var(--background-blue, #3F51B5);
        border-radius: 4px;
        color: #FFF;
        transition: all .2s ease;

        &:hover {
           background: #3f51b5d8; 
        }
        &:active {
           background: #3f51b5bd; 
        }
    }
    .param-line {
        margin-bottom: 10px;
    }

    .compressor-list {
        flex-grow: 3;
        // scrollbar-width: thin;
        // scrollbar-color: var(--gray-dark-color) var(--background-white);
        overflow: auto;
        .loader-part{
            display: flex;
            justify-content: center;
        }
        &::-webkit-scrollbar {
            width: 2px;
        }
        &::-webkit-scrollbar-track {
        background: none;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
        background: var(--gray-dark-color);
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
        background: rgb(122, 122, 122);
        }
        

        .compressor-line {
            padding: 10px 5px;
            border: 1px solid var(--gray-dark-color);
            border-radius: 4px;
            margin-bottom: 10px;
            transition: all .4s;

            &:hover {
                background: #94949417;
            }
            &:active {
                background: #9494943a;
            }
        }
    }
}

</style>
