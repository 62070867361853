<template>
    <div 
        class="company-line"
        :class="{
            choosed: flagChoose
        }"
    >
        <div class="data-part">
            <h2 class="name-company plain-text">{{name}}</h2>
            <p class="number-us small-text">пользователей: {{usersNumber}}</p>
        </div>
        <button v-if="flagChoose" @click.stop="closeCh">
            <img src="@/assets/imgs/company/cross-company.svg" alt="cross">
        </button>
    </div>
</template>

<script>
export default {
    name: 'CompanyLine',
    props: ['name', 'usersNumber', 'flagChoose'],
    emits: ['closeChoose'],
    computed: {
    },
    data() {
        return {
        }
    },
    components: {
    },
    methods: {
        closeCh() {
            this.$emit('closeChoose')
        }
    },
    created() {
    },
    beforeUnmount() {
    }
}
</script>

<style noscoped lang="scss">
    .company-line {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid var(--gray-dark-color);
        border-radius: 4px;
        padding: 5px 14px;
        transition: all .2s ease;

        &:not(:first-child) {
            margin-top: 12px;
        }

        &:hover {
            background: #94949417;
        }

        &:active {
            background: #9494943a;
        }

        .data-part {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: start;

            p {
                color: var(--gray-dark-color);
            }
        }
        button {
            display: flex;
            background: none;
            cursor: pointer;
        }
    }
    .company-line.choosed {
        background: var(--gray-dark-color);

        .data-part {
            p {
                color: var(--gray-color);
            }
        }
    }
</style>