<template>
    <div style="margin: 15px 0px">
        <div class="main-place-collapse" :name-line="nameCollapse" @click="openCollapse">
            <img src="@/assets/imgs/arrow-down-blue.svg" alt="" :class="{ 'opened-collapse': statusOpen }">
        </div>
        <div class="content-place" ref="content" :style="{ height: heightContent +'px', opacity: opacityParam}">
            <slot />
        </div>
    </div>
</template>
<script>
export default {
    name: 'MyCollapse',
    props: ['nameCollapse'],
    data() {
        return {
            statusOpen: false,
            opacityParam: 0,
            heightContent: 0
        }
    },
    methods: {
        openCollapse() {
            this.statusOpen = !this.statusOpen;
            if (this.statusOpen) {
                const contentElement = this.$refs.content;
                if (contentElement) {
                    this.heightContent = contentElement.scrollHeight;
                }
                this.opacityParam = 1;
            } else {
                this.opacityParam = 0;
                this.heightContent = 0;
                
            }
        }
    },
}
</script>
<style noscoped lang="scss">
.main-place-collapse {
    // display: flex;
    position: relative;

    &::after {
        content: attr(name-line);
        position: absolute;
        width: 80vw;
        top: -11px;
        left: 4vw;
        border-bottom: 1px solid;
        border-color: var(--background-blue);
        padding: 3px 17px;
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0%;
    }
    
    img {
        rotate: -90deg;
        transition: rotate .2s ease;
    }

    .opened-collapse {
        rotate: 0deg
    }
}
.content-place {
    overflow: hidden;
    transition: all .2s ease;
    padding: 24px 30px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}
@media screen and (max-width: 1210px) {
    .content-place {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media screen and (max-width: 810px) {
    .content-place {
        grid-template-columns: repeat(1, 1fr);
        padding: 12px 9px;
    }
}

</style>