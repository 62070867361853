<template>
    <div class="board-place">
        <div class="work-line">
            <div class="work-part">
                <img src="@/assets/imgs/LogoMvlab.svg" class="wide-type" alt="">
                <img
                    src="@/assets/imgs/menu-line.svg"
                    style="cursor: pointer"
                    v-if="!windowMenu"
                    class="narrow-type"
                    alt=""
                    @click="clickMenuLine"
                >
                <img 
                    src="@/assets/imgs/crosser.svg"
                    style="cursor: pointer"
                    v-if="windowMenu"
                    class="narrow-type"
                    alt=""
                    @click="closeAllWindow"
                >
                <button
                    class="middle-high"
                    :class="{
                        choosen: choosenPage == 'dashboard'
                    }"
                    @click="clickDashboard"
                >Dashboard</button>
                <button
                    class="middle-high"
                    :class="{
                        choosen: choosenPage == 'company'
                    }"
                    v-if="role == 'developer' || role == 'dealer' || role == 'admin'"
                    @click="clickCompany"
                >Company</button>
                <button
                    class="middle-high"
                    :class="{
                        choosen: choosenPage == 'modem'
                    }"
                    v-if="role == 'developer' || role == 'admin'"
                    @click="clickModem"
                >Modem</button>
            </div>
            <div class="user-part" @click="clickUserLine">
                <img src="@/assets/imgs/profile.svg" alt="">
                <p class="plain-text">{{username}}</p>
                <img src="@/assets/imgs/arrow-down.svg" alt="" class="arrow-img" :class="{choosen: windowUser}">
            </div>
        </div>
        <div class="shadow-place" v-if="windowShadow" @click.self="closeAllWindow">
            <div class="user-window" v-if="windowUser">
                <p class="plain-text">Приветствую, {{username}}</p>
                <p class="plain-text">Роль: {{role}}</p>
                <button @click="logOut">
                    <img src="@/assets/imgs/exit.svg" alt="">
                    Выйти
                </button>
            </div>
            <div class="menu-window" v-if="windowMenu">
                <button class="plain-text choosen">Dashboard</button>
                <button class="plain-text">Company</button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'HighBoard',
    data() {
        return {
            choosenPage: 'dashboard',
            windowShadow: false,
            windowUser: false,
            windowMenu: false
        }
    },
    props: ["username", "role"],
    emits: ['logOut', 'clickCompany', 'clickDashboard', 'clickModem'],
    methods: {
        clickCompany() {
            this.choosenPage = 'company'
            this.$emit('clickCompany')
        },
        clickDashboard() {
            this.choosenPage = 'dashboard'
            this.$emit('clickDashboard')
        },
        clickModem() {
            this.choosenPage = 'modem'
            this.$emit('clickModem')
        },
        logOut() {
            this.$emit('logOut')
        },
        clickUserLine() {
            if (this.windowUser) {
                this.windowShadow = false;
            } else {
                this.windowShadow = true;
            }
            
            this.windowUser = !this.windowUser;
            this.windowMenu = false;
        },
        clickMenuLine() {
            this.windowShadow = true;
            this.windowMenu = !this.windowMenu;
            this.windowUser = false;
        },
        closeAllWindow() {
            this.windowShadow = false;
            this.windowUser = false;
            this.windowMenu = false;
        }
    }
}
</script>
<style noscoped lang="scss">
.wide-type {
    display: block;
}
.narrow-type {
    display: none;
}
.board-place{    
    background: #3F51B5;
    box-shadow: 0px 4px 18px 7px rgba(0, 0, 0, 0.45);
    width: 100vw;
    height: 7vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .shadow-place {
        z-index: 2;
        right: 0;
        top: 100%;
        position: absolute;
        background: rgba(0, 0, 0, 0.17);
        width: 100vw;
        height: 93vh;
        cursor: pointer;

        .user-window {
            position: absolute;
            cursor: auto;
            width: 204px;
            height: 120px;
            background: var(--background-white);
            top: 5px;
            right: 5px;
            padding: 20px 22px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            button {
                background: none;
                cursor: pointer;
                display: flex;
                align-items: center;

                img {
                    margin-right: 14px;
                }
            }
        }

        .menu-window {
            position: absolute;
            cursor: auto;
            left: 5px;
            top: 5px;
            width: 123px;
            height: 58px;
            background: var(--background-blue);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 9px 0;

            button {
                background: none;
                color: var(--gray-dark-color);
                cursor: pointer;
            }

            .choosen {
                color: var(--background-white);
            }

        }
    }
}
.work-line{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .work-part {
        display: flex;
        margin-left:15px;
        img {
            margin-right: 2.5vw;
        }
        button {
            margin-right: 1vw;
            color: var(--gray-dark-color);
            background: none;
            cursor: pointer;
        }

        .choosen {
            color: var(--background-white);
        }
    }

    .user-part {
        cursor: pointer;
        margin-right: 33px;
        display: inline-flex;
        color: var(--background-white);
        align-items: center;
        gap: 10px;
        flex-shrink: 0;


        .arrow-img {
            transition: rotate .4s ease;
        }

        .choosen {
            rotate: 180deg;
        }
    }
}

@media screen and (max-width: 500px) {
    .work-part {
        button {
            display: none;
        }
        
        img {
            src: url('@/assets/imgs/menu-line.svg');
        }
    }
    .wide-type {
        display: none;
    }
    .narrow-type {
        display: block;
    }
}
</style>