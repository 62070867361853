<template>
    <div class="background-window" @click.self="closeWindow">
        <div class="window-graph">
            <div class="head-window">
                <h2 class="name-window-param middle-high">{{nameWindow}}</h2>
                <button @click="closeWindow">
                    <img src="@/assets/imgs/crosser-black.svg" alt="">
                </button>
            </div>
            <div class="contral-line">
                <div class="form-part">
                    <div class="el-form" name-line="Период:">
                        <el-date-picker
                            v-model="date"
                            type="datetimerange"
                            start-placeholder="Начальная дата"
                            end-placeholder="Конечная дата"
                            size="small"
                            format="DD/MM/YYYY HH:mm"
                            popper-class="datepicker-custom"
                        />
                    </div>
                    <div class="el-form" name-line="Параметры:">
                        <el-select
                            v-model="checkboxObject"
                            multiple
                            collapse-tags
                            collapse-tags-tooltip
                            size="small"
                            placeholder="Выберите параметры"
                        >
                            <el-option
                                v-for="item in optionsSelector"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            />
                        </el-select>
                    </div>
                    <div class="el-form" @mouseleave="hideDirectboard(true)" @mouseover="hideDirectboard(false)">
                        <img id="img-info" src="@/assets/imgs/info-circle.svg" alt="">
                        <div class="info-board" :class="{ 'showe-board': discriberBoard }">
                            <p class="plain-text">Справка по параметрам</p>
                        </div>
                    </div>
                </div>
                <button class="button-load-data" @click="getData">Посмотреть</button>
            </div>
            <highcharts id="testHeigh" class="highchart" :options="chartOptions" />
        </div>
    </div>
</template>
<script>
import { Chart } from 'highcharts-vue'
import axios from '../../../config-axios'
import { ElMessage } from 'element-plus'

export default {
    name: "WindowGraph",
    props: ['id', 'name', 'mac', 'type_controller'],
    emits: ['closeWindow'],
    computed: {
        nameWindow() {
            if (this.name) {
                return this.name
            } else {
                return this.mac
            }
        },
        optionsSelector() {
            let optionsForSelector = [
                {
                    value: 'current_engine',
                    label: 'Ток главного двигателя'
                },
                {
                    value: 'pressure_out',
                    label: 'Давление на выходе'
                },
                {
                    value: 'temperature',
                    label: 'Температура'
                },
                // {
                //     value: 'vibration',
                //     label: 'Вибрация'
                // },
                {
                    value: 'error',
                    label: 'Активная ошибка'
                },
                {
                    value: 'status',
                    label: 'Статус установки'
                },
                {
                    value: 'onload_time',
                    label: 'Часы с нагрузкой'
                },
                {
                    value: 'total_time',
                    label: 'Общее время с момента включения'
                },
                {
                    value: 'time_air_filter',
                    label: 'Время до замены воздушного фильтра'
                },
                {
                    value: 'time_oil',
                    label: 'Время до замены масляного фильтра'
                },
                {
                    value: 'time_separator_filter',
                    label: 'Время до замены фильтра сепаратора'
                },
                {
                    value: 'time_change_oil_liquid',
                    label: 'Время до замены масла'
                },
                {
                    value: 'time_el_system',
                    label: 'Время до обслуживания эл.системы'
                }
            ]
            if (this.type_controller == 'Q1') {
                return [
                    {
                        value: 'pressure_in',
                        label: 'Давление внутреннее'
                    },
                    {
                        value: 'off_load_time',
                        label: 'Часы без нагрузки'
                    },
                    {
                        value: 'work_percent',
                        label: 'Часы с нагрузкой/без нагрузки'
                    },
                    ...optionsForSelector
                ]
            } else {
                return [
                    {
                        value: 'voltage',
                        label: 'Напряжение'
                    },
                    {
                        value: 'alarm',
                        label: 'Сигнализация'
                    },
                    ...optionsForSelector
                ]
            }
        }
    },
    data() {
        return {
            discriberBoard: false,
            date: [],
            checkboxObject: [],
            chartOptions: {
                chart: {
                    zooming: {
                        type: 'x'
                    },
                    backgroundColor: "#FCFCFC",
                    events: {
                        redraw: this.countDots
                    },
                },
                title: {
                    text: ""
                },
                credits: {
                    enabled: false
                },
                tooltip: {
                    shared: true,
                    crosshairs: true
                },
                series: [],
                accessibility: {enabled: false},
                time: {
                    timezone: "Europe/Minsk",
                    timezoneOffset: 3,
                    useUTC:false
                },
                legend:{
                    // width: 350,
                    itemDistance: 5,
                    lineHeight: 14,
                    margin: 2,
                    maxHeight: 46
                },
                xAxis: {
                    type: 'datetime'
                },
                yAxis: {
                    title: {
                        enabled: false
                    }
                },
            },
            numberDots: 69
        }
    },
    methods: {
        countDots(e) {
            let number = 0
            e.target.series.forEach(el => {
                number += el.points.length;
            })
            this.numberDots = number
        },
        hideDirectboard(status) {
            if (status) {
                this.discriberBoard = false
            } else {
                this.discriberBoard = true
            }
            
        },
        closeWindow() {
            this.$emit('closeWindow')
        },
        getData() {
            this.chartOptions.series = [];
            this.chartOptions.series.forEach(el=> {
                el.visible = false
            })
            const flagQ1 = this.type_controller == 'Q1';
            let checkboxParam = {
                pressure_in: false,
                pressure_out: false,
                vibration: false,
                temperature: false,
                error: false,
                status: false,
                onload_time: false,
                off_load_time: false,
                total_time: false,
                work_percent: false,
                time_air_filter: false,
                time_oil: false,
                time_separator_filter: false,
                time_change_oil_liquid: false,
                time_el_system: false,
                current_engine: false,
                voltage: false,
                alarm: false
            }
            this.checkboxObject.forEach(el => {
                if (el == 'pressure_in') checkboxParam.pressure_in = true;
                if (el == 'pressure_out') checkboxParam.pressure_out = true;
                if (el == 'vibration') checkboxParam.vibration = true;
                if (el == 'temperature') checkboxParam.temperature = true;
                if (el == 'error') checkboxParam.error = true;
                if (el == 'status') checkboxParam.status = true;
                if (el == 'onload_time') checkboxParam.onload_time = true;
                if (el == 'off_load_time') checkboxParam.off_load_time = true;
                if (el == 'total_time') checkboxParam.total_time = true;
                if (el == 'work_percent') checkboxParam.work_percent = true;
                if (el == 'time_air_filter') checkboxParam.time_air_filter = true;
                if (el == 'time_oil') checkboxParam.time_oil = true;
                if (el == 'time_separator_filter') checkboxParam.time_separator_filter = true;
                if (el == 'time_change_oil_liquid') checkboxParam.time_change_oil_liquid = true;
                if (el == 'time_el_system') checkboxParam.time_el_system = true;
                if (el == 'current_engine') checkboxParam.current_engine = true;
                if (el == 'voltage') checkboxParam.voltage = true;
                if (el == 'alarm') checkboxParam.alarm = true;
            })
            let options = {
                id: this.id,
                start_time: this.date[0].toLocaleString('en-GB', { timeZone: 'Europe/Minsk' }),
                end_time: this.date[1].toLocaleString('en-GB', { timeZone: 'Europe/Minsk' }),
                param: checkboxParam
            }
            axios.post('compressor/graph/', options).then(response=>{
                response.data.pressure_in.length?this.pushDataToGraph('Давление внутреннее', 'бар', response.data.pressure_in, 1000):null;
                response.data.pressure_out.length?this.pushDataToGraph('Давление на выходу', 'бар', response.data.pressure_out, flagQ1?1000:10):null;
                response.data.vibration.length?this.pushDataToGraph('Вибрация', 'мм/с', response.data.vibration, 1):null;
                response.data.temperature.length?this.pushDataToGraph('Температура', '°C', response.data.temperature, flagQ1?1000:1):null;
                response.data.error.length?this.pushDataToGraph('Ошибка', '', response.data.error, 1):null;
                response.data.status.length?this.pushDataToGraph('Статус', '', response.data.status, 1):null;
                response.data.onload_time.length?this.pushDataToGraph('Часы с нагрузкой', 'ч', response.data.onload_time, 1):null;
                response.data.off_load_time.length?this.pushDataToGraph('Часы без нагрузки', 'ч', response.data.off_load_time, 1):null;
                response.data.total_time.length?this.pushDataToGraph('Время с момента включения', 'ч', response.data.total_time, 1):null;
                response.data.work_percent.length?this.pushDataToGraph('Часы с нагрузкой/без нагрузки', 'ч', response.data.work_percent, 1):null;
                response.data.time_air_filter.length?this.pushDataToGraph('До замены воздушного фильтра', 'ч', response.data.time_air_filter, 1):null;
                response.data.time_oil.length?this.pushDataToGraph('До замены масляного фильтра', 'ч', response.data.time_oil, 1):null;
                response.data.time_separator_filter.length?this.pushDataToGraph('До замены фильтра сепаратора', 'ч', response.data.time_separator_filter, 1):null;
                response.data.time_change_oil_liquid.length?this.pushDataToGraph('До замены масла', 'ч', response.data.time_change_oil_liquid, 1):null;
                response.data.time_el_system.length?this.pushDataToGraph('До обслуживания эл.сис.', 'ч', response.data.time_el_system, 1):null;
                response.data.current_engine.length?this.pushDataToGraph('Ток главного двигателя', 'А', response.data.current_engine, 10):null;
                
                response.data.alarm.length?this.pushDataToGraph('Сигнализация', '', response.data.alarm, 1):null;
                response.data.voltage.length?this.pushDataToGraph('Напряжение', '', response.data.voltage, 1):null;
                if (this.chartOptions.series.length === 0) {
                    ElMessage('За данный период данных нет')
                }
            })
        },
        pushDataToGraph(name, unit, dataArray, divider) {
            let indexChart = null;
            this.chartOptions.series.find((el, index)=>{
                if (el.name == name) {
                    indexChart = index
                    return true
                }
            })
            if (indexChart == null) {
                const emptySeries = {
                    data: [],
                    name: name,
                    turboThreshold:10000,
                    visible: false,
                    tooltip: {
                        pointFormat: '<b>'+name+':</b> {point.y} <i>'+ unit + '</i><br>',
                    }
                }
                indexChart = this.chartOptions.series.push(emptySeries) - 1;
            }
            var newArrayChart = dataArray.map(dote=>{
                const date1 = new Date(dote[0])
                return [date1.valueOf(), dote[1]/divider]
            })
            
            this.chartOptions.series[indexChart].data = newArrayChart
            if (newArrayChart.length > 0) {
                this.chartOptions.series[indexChart].visible = true
            }
        }
    },
    components: {
        highcharts: Chart 
    },
}
</script>
<style noscoped lang="scss">
.highchart{
    // height: 100%;
    flex-grow: 3;
}

.background-window{
    position: absolute;
    z-index: 2;
    top:0;
    left:0;
    background: rgba(0, 0, 0, 0.295);
    width: 100vw;
    height: 100vh;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

}

.window-graph{
    background: var(--background-white, #FCFCFC);
    width: 98%;
    height: 90%;
    cursor: auto;
    border-radius: 4px;
    padding: 12px 9px;
    display: flex;
    flex-direction: column;

    
    .head-window {
        display: flex;
        justify-content: space-between;

        button {
            padding: 1px 2px;
            background: none;
            cursor: pointer;
        }
    }

    .contral-line {
        margin: 15px 25px;
        display: flex;
        justify-content: space-between;

        .form-part  {
            display: flex;

            // & * {
            //     margin-right: 10px;
            // }
            .el-form:not(:first-child) {
                margin-left: 31px;
                display: flex;
                justify-content: center;
                position: relative;
    
                #img-info {
                    height: 20px;
                    display: flex;
                    align-self: center;
                }

                .info-board {
                    padding: 5px 10px;
                    z-index: 3;
                    position: absolute;
                    top: 115%;
                    background: red;
                    width: 28vw;
                    min-width: 320px;
                    // height: 344px;
                    border-radius: 4px;
                    background: var(--background-white, #FCFCFC);
                    box-shadow: 0px 0px 28.1px 2px rgba(0, 0, 0, 0.25);
                    transition: all .2s ease;
                    opacity: 0;
                    display: none;
                }

                .showe-board {
                    opacity: 1;
                    display: inline;
                }
            }
        }

        .button-load-data {
            cursor: pointer;
            display: flex;
            width: 200px;
            height: 23px;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            background: var(--background-blue, #3F51B5);
            color: var(--background-white, #FCFCFC);
            transition: all .2s ease;
            box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2);
            
        
            &:hover {
                box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2);
            }
            &:active {
                box-shadow: none;
            }
        }
    }
}



@media screen and (max-width: 600px) {
    .el-date-editor.el-input__wrapper {
        width: 100% !important;
    }
    .el-select--small {
        width: 87%;
    }
    // .el-input {
    //     width: 100% !important;
    // }
    // .button-load-data {
    //     margin: 5px 10px !important;
    // }
    .datepicker-custom {
        .el-input__inner{
            height: 18px;
        }
        .el-date-range-picker {
            width: 323px;
            .el-picker-panel__body {
                min-width: 323px;
            }
        }
        .el-picker-panel {
            line-height: 20px;
        }
        .el-picker-panel__content {
            float: none;
            width: 100%;
            padding: 2px;

            .el-date-range-picker__header {
                height: 23px;
            }
            .el-date-table th {
                padding:2px 5px;
            }
            .el-date-table td {
                padding: 0;
            }
        }
        .el-date-range-picker__time-header {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    .window-graph {
        .head-window {
            margin-bottom: 10px;
        }
        .contral-line {
            flex-direction: column;
            margin: 5px 0px;

            .form-part  {
                flex-direction: column;
                position: relative;

                .el-form:not(:first-child) {
                    margin-left: 0;
                    justify-content: flex-start;
                    margin-top: 20px;
                }

                .el-form:last-child {
                    position: absolute;
                    right: 0;
                    bottom: 0;

                    .info-board {
                        right: 0px;
                    }
                }
                .el-form:not(:last-child) {
                    
                    position: relative;
                }
                .el-form:not(:last-child)::before {
                    content: attr(name-line);
                    position: absolute;
                    bottom: 100%;
                    font-family: Lato;
                    font-size: 9px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
            
            .button-load-data {
                width: 100%;
                margin-top: 10px;
            }
            
        }
    }
}
</style>