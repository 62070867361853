<template>
    <button>
        <div v-if="!loading">{{text}}</div>
        <div v-if="loading" class="line-1-horizontal"></div>
    </button>
</template>

<script>
export default {
    name: 'Button',
    data() {
        return {
            
        }
    },
    props: {
        text: String,
        loading: Boolean,
    }
}
</script>
<style scoped lang="scss">

button{
    border-radius: 0.5625rem;
    cursor: pointer;
    color: #FCFCFC;
    background: #3F51B5;
    transition: all .3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}
button:hover{
    background-color: #3f51b5f1;
}
button:active{
    background-color: #3f51b5c7;
}

.line-1-horizontal {
  --size: 24px;
  --stroke-width: calc(var(--size) / 6);
  --color: currentColor;
  --animation-timing-function: cubic-bezier(0.85, 0, 0.15, 1);
  --animation-duration: 2s;
  width: var(--size);
  height: var(--stroke-width);
  background-color: var(--color);
  transform: scaleX(0);
  transform-origin: center left;
  animation: var(--animation-timing-function) var(--animation-duration) infinite line-1-horizontal-animation;
}

@keyframes line-1-horizontal-animation {
  0% {
    transform: scaleX(0);
    transform-origin: center left;
  }

  50% {
    transform: scaleX(1);
    transform-origin: center left;
  }

  51% {
    transform: scaleX(1);
    transform-origin: center right;
  }

  100% {
    transform: scaleX(0);
    transform-origin: center right;
  }
}
</style>
